import makeStyles from '@mui/styles/makeStyles';
import colors from '../styles/colors';

export const useStyles = makeStyles({
  root: {
    backgroundColor: colors.gray.l96,
    '& a': {
      textDecoration: 'none',
    },
  },
  iframe: {
    boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.12)',
    borderRadius: '12px',
    border: 'none',
  },
});
