import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.gray.l96,
    isolation: 'isolate',
    '& a': {
      textDecoration: 'none',
    },
    '& button': {
      border: `1px solid ${colors.black}`,
      backgroundColor: colors.white,
      '&:hover': {
        backgroundColor: undefined,
      },
    },
  },
  bannerTextHeader: {
    fontSize: '4.4rem',
    lineHeight: '5.3rem',
    fontWeight: 700,
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '3.6rem',
    },
  },
}));
