import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useI18nContext } from '@procore/core-react';
import Header from '../components/Layout/Header';
import Logo404 from '../components/404/Logo404';
import IE404 from '../components/404/IE404';
import { useStyles } from './404.styles';
import { usePageView } from '../components/Analytics/utilities';
import AnalyticsWrappedComponent from '../components/Analytics/AnalyticsWrappedComponent';
import basePath from '../helpers/basePath';

export default function Custom404() {
  const I18n = useI18nContext();
  const classes = useStyles();
  const isIE = useMediaQuery(
    'all and (-ms-high-contrast: none), (-ms-high-contrast: active)'
  );
  usePageView('404 Page');

  if (isIE) {
    return <IE404 />;
  }

  return (
    <section role="main">
      <Header />
      <Box
        minHeight="calc(100vh - 83px)"
        width="100%"
        className={classes.root}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Box display="flex" flexDirection="column" mt={5} tabIndex={0}>
          <Logo404 />
          <Box m="auto">
            <h1>{I18n.t('pages.404.not_found')}</h1>
          </Box>
        </Box>
        <Box mt={5}>
          <iframe
            src="https://farism.github.io/craneium/"
            title="Craneium Game"
            frameBorder="0"
            width="1024px"
            height="640px"
            className={classes.iframe}
          />
        </Box>
        <Box maxWidth="500px" textAlign="center" mt={3}>
          <Typography variant="body1">{I18n.t('pages.404.game')}</Typography>
        </Box>
        <Box mt={2} mb={5}>
          <AnalyticsWrappedComponent
            componentType="a"
            eventText="Clicked Back to Construction Network"
            eventLabel="404 Page"
            data-track-click="404, Navigation, Back to Construction Network"
            href={basePath()}
            tabIndex={-1}
          >
            <Button data-test-id="back-button" variant="outlined">
              {I18n.t('pages.404.back')}
            </Button>
          </AnalyticsWrappedComponent>
        </Box>
      </Box>
    </section>
  );
}
