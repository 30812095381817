import React from 'react';

const Logo404 = () => {
  return (
    <svg
      width="227"
      height="85"
      viewBox="0 0 227 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>404</title>
      <g filter="url(#filter0_i)">
        <path
          d="M0 69.4073V53.9746L33.3392 1.11947H58.0953V53.8147H67.8308V69.4073H58.0953V83.0009H39.2997V69.4073H0ZM39.6971 53.8147V22.0696H39.0613L19.5505 53.175V53.8147H39.6971Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M113.142 85C106.043 85 99.91 83.3075 94.7442 79.9224C89.6049 76.5107 85.6445 71.6196 82.8629 65.2493C80.1078 58.8523 78.7435 51.1626 78.77 42.1801C78.7965 28.7198 81.8695 18.3247 87.989 10.9948C94.1084 3.66494 102.493 0 113.142 0C120.242 0 126.361 1.65256 131.501 4.95767C136.667 8.23612 140.64 13.0205 143.422 19.3109C146.203 25.6013 147.581 33.2244 147.554 42.1801C147.554 51.1892 146.164 58.8923 143.382 65.2893C140.601 71.6863 136.64 76.5773 131.501 79.9624C126.361 83.3208 120.242 85 113.142 85ZM113.142 68.8076C117.407 68.8076 120.838 66.6353 123.434 62.2907C126.057 57.9194 127.355 51.2159 127.328 42.1801C127.302 33.331 125.991 26.7741 123.394 22.5094C120.798 18.2181 117.381 16.0724 113.142 16.0724C108.93 16.0724 105.526 18.2181 102.93 22.5094C100.334 26.7741 99.0226 33.331 98.9961 42.1801C98.9696 51.2159 100.254 57.9194 102.851 62.2907C105.473 66.6353 108.904 68.8076 113.142 68.8076Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M159.169 69.4073V53.9746L192.508 1.11947H217.264V53.8147H227V69.4073H217.264V83.0009H198.469V69.4073H159.169ZM198.866 53.8147V22.0696H198.231L178.72 53.175V53.8147H198.866Z"
          fill="url(#paint2_linear)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i"
          x="0"
          y="0"
          width="227"
          height="86"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="114"
          y1="-9"
          x2="113.514"
          y2="85.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3E6E7" />
          <stop offset="1" stopColor="#EEF0F1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="114"
          y1="-9"
          x2="113.514"
          y2="85.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3E6E7" />
          <stop offset="1" stopColor="#EEF0F1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="114"
          y1="-9"
          x2="113.514"
          y2="85.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3E6E7" />
          <stop offset="1" stopColor="#EEF0F1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Logo404;
