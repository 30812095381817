import { Box, Button } from '@mui/material';
import React from 'react';
import { useI18nContext } from '@procore/core-react';
import Header from '../Layout/Header';
import { useStyles } from './IE404.styles';
import AnalyticsWrappedComponent from '../Analytics/AnalyticsWrappedComponent';
import basePath from '../../helpers/basePath';

export const Ladder404 = () => {
  return (
    <svg
      width="643"
      height="465"
      viewBox="0 0 643 465"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 195.974V152.399L94.4366 3.16087H164.561V151.947H192.137V195.974H164.561V234.356H111.32V195.974H0ZM112.446 151.947V62.3142H110.645L55.3788 150.141V151.947H112.446Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M320.487 240C300.377 240 283.005 235.221 268.372 225.663C253.815 216.03 242.596 202.22 234.717 184.233C226.913 166.171 223.049 144.459 223.124 119.097C223.199 81.0912 231.903 51.7404 249.237 31.0442C266.571 10.3481 290.321 0 320.487 0C340.597 0 357.931 4.66604 372.489 13.9981C387.122 23.2549 398.377 36.7639 406.257 54.5249C414.136 72.286 418.038 93.81 417.963 119.097C417.963 144.534 414.023 166.284 406.144 184.346C398.265 202.408 387.047 216.218 372.489 225.776C357.931 235.259 340.597 240 320.487 240ZM320.487 194.28C332.568 194.28 342.286 188.147 349.64 175.88C357.068 163.537 360.745 144.61 360.67 119.097C360.595 94.111 356.881 75.5974 349.527 63.556C342.173 51.4393 332.493 45.381 320.487 45.381C308.556 45.381 298.913 51.4393 291.559 63.556C284.206 75.5974 280.491 94.111 280.416 119.097C280.341 144.61 283.98 163.537 291.334 175.88C298.763 188.147 308.481 194.28 320.487 194.28Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M450.863 195.974V152.399L545.299 3.16087H615.423V151.947H643V195.974H615.423V234.356H562.183V195.974H450.863ZM563.309 151.947V62.3142H561.508L506.241 150.141V151.947H563.309Z"
        fill="url(#paint2_linear)"
      />
      <mask
        id="mask0"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="252"
        y="419"
        width="360"
        height="46"
      >
        <ellipse cx="432" cy="442" rx="180" ry="23" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0)">
        <ellipse cx="432" cy="442" rx="180" ry="23" fill="#6A767C" />
        <ellipse cx="432" cy="454" rx="180" ry="23" fill="#2F3437" />
      </g>
      <mask
        id="mask1"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="252"
        y="79"
        width="360"
        height="386"
      >
        <path
          d="M612 441.5C612 454.203 531.411 464.5 432 464.5C332.589 464.5 252 454.203 252 441.5C252 428.797 316.589 79 416 79C515.411 79 612 428.797 612 441.5Z"
          fill="#6A767C"
        />
      </mask>
      <g mask="url(#mask1)">
        <path d="M346 191.5H359V486.5H346V191.5Z" fill="#E64900" />
        <rect x="359" y="186.5" width="13" height="295" fill="#CC4100" />
        <path d="M359 191.5L372 186.5H359L346 191.5H359Z" fill="#FF5100" />
        <path d="M492 191.5H505V486.5H492V191.5Z" fill="#E64900" />
        <rect x="505" y="186.5" width="13" height="295" fill="#CC4100" />
        <path d="M505 191.5L518 186.5H505L492 191.5H505Z" fill="#FF5100" />
        <ellipse cx="365" cy="233.5" rx="2" ry="4" fill="#FF5100" />
        <rect x="365" y="229.5" width="127" height="8" fill="#FF5100" />
        <ellipse cx="365" cy="319.5" rx="2" ry="4" fill="#FF5100" />
        <rect x="365" y="315.5" width="127" height="8" fill="#FF5100" />
        <ellipse cx="365" cy="405.5" rx="2" ry="4" fill="#FF5100" />
        <rect x="365" y="401.5" width="127" height="8" fill="#FF5100" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="316"
          y1="-3.56183e-08"
          x2="321.5"
          y2="240"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D6DADC" />
          <stop offset="1" stopColor="#D6DADC" stopOpacity="0.16" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="316"
          y1="-3.56183e-08"
          x2="321.5"
          y2="240"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D6DADC" />
          <stop offset="1" stopColor="#D6DADC" stopOpacity="0.16" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="316"
          y1="-3.56183e-08"
          x2="321.5"
          y2="240"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D6DADC" />
          <stop offset="1" stopColor="#D6DADC" stopOpacity="0.16" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const IE404 = () => {
  const I18n = useI18nContext();
  const classes = useStyles();
  return (
    <>
      <Header />
      <Box
        minHeight="calc(100vh - 83px)"
        width="100%"
        className={classes.root}
        display="flex"
        alignItems="center"
        p={6}
      >
        <Box position="absolute" height="100%" zIndex="-1" right="10%">
          <Box
            display="flex"
            alignItems="center"
            height="100%"
            marginTop="-10%"
          >
            <Ladder404 />
          </Box>
        </Box>
        <Box>
          <Box maxWidth="640px" className={classes.bannerTextHeader}>
            {I18n.t('components.ie404.oops')}
          </Box>
          <Box mt={4}>
            <AnalyticsWrappedComponent
              componentType="a"
              eventText="Clicked Back to Construction Network"
              eventLabel="IE 404 Page"
              href={basePath()}
              data-track-click="IE404, Navigation, Back to Construction Network"
            >
              <Button
                data-track-click="IE404 Page, Navigation, Back to Construction Network"
                variant="outlined"
              >
                {I18n.t('components.ie404.back')}
              </Button>
            </AnalyticsWrappedComponent>
          </Box>
        </Box>

        <Box position="absolute" width="100%" bottom="30px">
          <Box display="flex" justifyContent="center">
            {I18n.t('components.ie404.page_not_found')}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IE404;
